import React from 'react';
import PropTypes from 'prop-types';

import { useStaticQuery, graphql, Link } from 'gatsby';

const TestSection = ({ name }) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          TEST_ICON {
            Form {
              title
              description
            }
            Check {
              title
              description
            }
          }
        }
      }
    `
  );

  return (
    <>
      {Data.hostingJson.TEST_ICON[name].map((widget, index) => (
        <div key={`applications-${index}`}>
          <ul>
            <li>{widget.title}</li>
            <li>{widget.description}</li>
          </ul>
        </div>
      ))}
    </>
  );
};

export default TestSection;
