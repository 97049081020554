import React, { Component } from 'react';
import TestSection from '../containers/Hosting/Test';

class SecondPage extends Component {
  render() {
    const name = 'Check';
    return (
      <>
        <p>Second Page</p>
        <TestSection name={name} />
      </>
    );
  }
}

export default SecondPage;
